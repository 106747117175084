import React, {
  useEffect, useState, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Paper, Box, Container } from '@mui/material';
import { Typography, Divider, Button } from '@material-ui/core';
import { isCancel } from 'axios';
import Header from '../common/Header';
import WarningInfoAlert from '../common/WarningInfoAlert';
import CustomSelect from '../common/CustomSelect';
import BreadCrumbHOC from '../BreadCrumbHOC';
import FileInput from '../FileInput';
import MainButton from '../MainButton';
import LoadingCircle from '../common/LoadingCircle/LoadingCircle';
import UserWarning from '../../assets/img/group-3.svg';
import {
  BULK_ACTION_USER_TEMPLATE, QUEUED, USER_DELETION,
  AUTO_HIDE_DURATION, ROUTE_ERROR, BULK_USER_CREATION,
  MASTER_DATA, CLIENT_MASTER, BULK_ACTION_USER_CREATION_TEMPLATE,

} from '../../constants';
import FormattedTypography from '../common/FormattedTypography';
import {
  MODERATE_DARK_GREY,
  MCKINSEY_BLUE, ERROR_RED,
} from '../../stylesheets/colors';
import XlsScanError from '../common/XlsScanError';
import ValidationError from '../../assets/img/validation-error.webp';
import { zeroAppender } from '../../helpers/formattingHelpers';
import CustomUploadModal from '../common/CustomUploadModal';
import { internalServerErrorModalLogic } from '../../containers/common/utils';
import ProgressStatusModal from './ProgressStatusModal';
import ErrorModal from '../ErrorModal/ErrorModal';
import EnrollmentUploadError from '../UserEnrollment/OperationsPage/EnrollmentUploadError';

import {
  getProgress,
} from '../../store/actions/async/common';
import AlertReleaseInfo from '../ContentManagement/AlertReleaseInfo';
import { fetchClientId } from '../../containers/WaveManagementPage/api';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    boxShadow: 'none',
  },
  breadCrumbSection: {
    display: 'flex',
    padding: '1.25rem 2rem',
  },
  headingSection: {
    padding: '0rem 2rem 1rem',
  },
  red: {
    color: ERROR_RED,
  },
  mainWrapper: {
    flexGrow: 1,
    padding: '2rem',
  },
  middleWrapper: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    padding: '1rem 2rem',
  },
  boldText: {
    fontWeight: 'bold',
    color: MODERATE_DARK_GREY,
  },
  userEnrollMentButton: {
    fontSize: '1.5rem',
  },
  border: {
    margin: '1.2rem 0',
    border: `0.5px dashed ${MCKINSEY_BLUE}`,
    width: '40%',
  },
  downloadWaveDataLbl: {
    marginTop: '1rem',
  },
  transferInfo: {
    marginBottom: '1rem',
    fontWeight: 'bold',
  },
  pageHeader: {
    padding: '0 2rem 2rem',
  },
  selectWrapper: {
    width: '25%',
  },
  label: {
    fontSize: '1.125rem',
    color: MODERATE_DARK_GREY,
    textTransform: 'unset',
    padding: '0.25rem 0',
    display: 'flex',
    alignItems: 'center',
  },
  fieldPadding: {
    paddingTop: '1rem',
  },
  divider: {
    marginTop: '1rem',
  },
  contentWrapper: {
    marginBottom: '0',
    marginTop: '5rem',
  },
}));

const WARNING_text = {
  Mark: 'This action will mark the users for deletion on the  platform. Once the user has been deleted by the Super Admin, the action is not reversible.',
  Unmark: 'This action will unmark the users for deletion on the platform. Once the user has been deleted by the Super Admin, the action can not be performed on those users.',
  User_creation: 'This action will create users on the platform, and all users will be tagged as active by default.',
};

const UPLOAD_text = {
  Mark: 'We will mark users uploaded for deletion',
  Unmark: 'We will unmark users uploaded for deletion',
  User_creation: '',
};

const SUBTYPE = {
  Mark: USER_DELETION,
  Unmark: USER_DELETION,
  User_creation: BULK_USER_CREATION,
};

const ALERTBAR_TEXT = {
  validate: {
    Mark: (name) => `Validating Mark for Deletion file '${name || ''}'`,
    Unmark: (name) => `Validating Unmark for Deletion file '${name || ''}'`,
    User_creation: (name) => `Validating User Creation file '${name || ''}'`,
  },
  progress: {
    Mark: (name) => `Uploading Mark for Deletion file '${name}'`,
    Unmark: (name) => `Uploading Unmark for Deletion file '${name}'`,
    User_creation: (name) => `Uploading Users Creation file '${name}'`,
  },
  complete_with_errors: {
    Mark: (name) => `Mark for Deletion file '${name}' upload was completed with errors.`,
    Unmark: (name) => `Unmark for Deletion file '${name}' upload was completed with errors.`,
    User_creation: (name) => `File '${name}' upload for user creation has been completed with errors.`,
  },
  error: {
    Mark: (name) => `Failed to upload Mark for Deletion file '${name}'. Please try again.`,
    Unmark: (name) => `Failed to upload Unmark for Deletion file '${name}'. Please try again.`,
    User_creation: (name) => `Failed to upload the file '${name}' for user creation. Please try again.`,
  },
  success: {
    Mark: (name) => `Mark for Deletion file '${name}' was successfully uploaded`,
    Unmark: (name) => `Unmark for Deletion file '${name}' was successfully uploaded`,
    User_creation: (name) => `File '${name}' for user creation has been successfully uploaded.`,
  },
};

const BulkUsersUpload = (props) => {
  const {
    fileValidate, isLoading, fileValidateError, message, fileUpload,
    transaction_id, clearMessage, clearTransactionId, isCpRole, fileValidateWarning, userCount,
    usersData,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const clientIdRef = useRef();
  const initialValidationResult = { status: 'none', errors: [] };
  const [selectAction, setSelectAction] = useState('');
  const [selectedClient, setSelectedClient] = useState({});
  const [actionText, setActionText] = useState('');
  const [fileData, setFileData] = useState({});
  const [modalState, setModalState] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [fileName, setFileName] = useState('');
  const [progress, setProgress] = useState({ done: null, percentage: 0 });
  const [transactionId, setTransactionId] = useState(null);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [showElement, setShowElement] = useState(false);
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [loaderText, setLoaderText] = useState('File upload is in progress...');
  const [clientIdList, setClientIdList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { result: { total_rows } } = usersData;
  const userActionOptions = [
    { label: 'User Creation', value: 'User_creation' },
    { label: 'Mark for Deletion', value: 'Mark', isdisabled: !total_rows },
    { label: 'Unmark for Deletion', value: 'Unmark', isdisabled: !total_rows },
  ];

  const [modalSchema, setModalScheme] = useState({
    VALIDATION: {
      component: XlsScanError,
      wrapperProps: {
        logo: <img src={ValidationError} alt="validation error" width={120} height={120} />,
        heading: null,
        primaryBtnText: 'Re-upload File',
        contentDivider: true,
        contentWrapper: classes.dialogContentWrapper,
        primaryBtnProps: {
          onClick: setModalState.bind(null, null),
        },
      },
      props: initialValidationResult,
    },
    WARNING: {
      component: EnrollmentUploadError,
      wrapperProps: {
        logo: <img src={ValidationError} alt="validation error" width={120} height={120} />,
        heading: null,
        primaryBtnText: 'Re-upload File',
        contentDivider: true,
        contentWrapper: classes.dialogContentWrapper,
        primaryBtnProps: {
          onClick: setModalState.bind(null, null),
        },
      },
      props: initialValidationResult,
    },
  });

  const toArrayOfObject = (arr) => arr.map((a) => ({ value: a.id, label: `${a.client_id} - ${a.client_name}` }));

  useEffect(() => {
    if (isCpRole) {
      history.replace(`/${ROUTE_ERROR}`);
    }
  }, [history, isCpRole]);

  const applyUploadHasErrorModal = useCallback((result) => {
    batchUpdates(() => {
      setModalState('WARNING');
      setUploadDisabled(false);
      setModalScheme((schema) => ({
        ...schema,
        WARNING: {
          ...schema.WARNING,
          wrapperProps: {
            ...schema.WARNING.wrapperProps,
            logo: <img src={UserWarning} alt="warning" width={120} height={120} />,
            heading: (
              <FormattedTypography prefix={'Discrepancies found in uploaded file'} />
            ),
            contentDivider: false,
            showActionButtons: false,
          },
          props: {
            setSchema: setModalState,
            totalUsers: userCount,
            warnings: result || [],
            formData: fileData,
            navigateToEnrollmentCohortPage: () => {},
            navigateToClientMaster: () => { history.push(`/${MASTER_DATA}/${CLIENT_MASTER}`); },
            hideMessage: true,
            cancelProp: null,
          },
        },
      }));
      clearMessage();
    });
  }, [clearMessage, fileData, history, userCount]);

  useEffect(() => {
    if (fileValidateError.length && !modalState) {
      const file = fileName?.name || '';
      const [extension, ...nameParts] = file.split('.').reverse();
      batchUpdates(() => {
        setModalState('VALIDATION');
        setUploadDisabled(false);
        setModalScheme((schema) => ({
          ...schema,
          VALIDATION: {
            ...schema.VALIDATION,
            wrapperProps: {
              ...schema.VALIDATION.wrapperProps,
              heading: (
                <FormattedTypography
                  prefix="Scanning file&nbsp;"
                  body={nameParts.length ? nameParts.join('.') : ''}
                  suffix={`${extension ? `.${extension}` : ''}`}
                  subHeading={`${zeroAppender(
                    fileValidateError.length,
                  )} errors found. Please make the required changes and re-upload your file.`}
                />
              ),
            },
            props: { errors: fileValidateError },
          },
        }));
        clearMessage();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileValidateError]);

  useEffect(() => {
    if (fileValidateWarning.length && !modalState) {
      applyUploadHasErrorModal(fileValidateWarning);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileValidateWarning]);

  useEffect(() => {
    if (message && message === 'File validation successful') {
      setLoaderText('File Upload is in progress...');
      fileUpload(SUBTYPE[selectAction], fileData);
      clearMessage();
    }
  }, [message, fileData, fileUpload, clearMessage, selectAction]);

  useEffect(() => {
    if (transaction_id) {
      setTransactionId(transaction_id);
      setUploadDisabled(true);
    }
  }, [transaction_id]);

  useEffect(() => {
    let handler;
    if (progress?.done) {
      setSelectAction('');
      setSelectedClient({});
      handler = setTimeout(() => {
        setShowElement(false);
      }, AUTO_HIDE_DURATION);
    }
    return () => {
      clearTimeout(handler);
    };
  }, [progress]);

  useEffect(() => {
    let timer = null;
    const pollProgressApi = async () => {
      try {
        const res = await getProgress(transactionId);
        const { done } = res.data;
        if (done) {
          clearTransactionId();
          batchUpdates(() => {
            setProgress(res.data);
            setTransactionId(null);
            setUploadDisabled(false);
          });
        } else {
          batchUpdates(() => {
            setProgress(res.data);
            setUploadDisabled(true);
            if (res.data.status === QUEUED) {
              setOpenStatusModal(true);
            }
          });
          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        if (isCancel(err)) {
          return;
        }
        timer = internalServerErrorModalLogic(history, err, setIsErrorModalOpen, pollProgressApi);
      }
    };
    if (transactionId) {
      pollProgressApi();
      setShowElement(true);
    }
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId]);

  const getClientId = useCallback(async () => {
    setLoading(true);
    try {
      const resp = await fetchClientId();
      if (!resp.data.success) throw new Error();
      const clientdata = resp?.data?.data.filter((item) => item.client_id !== '');
      batchUpdates(() => {
        setClientIdList(toArrayOfObject(clientdata));
        setLoading(false);
      });
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (selectAction === 'User_creation') {
      getClientId();
    }
  }, [getClientId, selectAction]);

  const onCloseModal = () => {
    setModalState(null);
  };

  const breadCrumbList = [
    { label: 'User Management', redirectionUrl: '/users', isActive: false },
    { label: 'Bulk Action XLS Upload', redirectionUrl: '', isActive: true },
  ];

  const onDownloadSample = async () => {
    const download_sheet = selectAction === 'User_creation' ? BULK_ACTION_USER_CREATION_TEMPLATE : BULK_ACTION_USER_TEMPLATE;
    window.open(download_sheet, '_blank');
  };

  const resetFileUpload = useCallback(
    (e) => {
      e.target.value = '';
    },
    [],
  );

  const onUpload = (e) => {
    const selectedFile = e.target.files[0];
    const formData = new FormData();
    resetFileUpload(e);
    formData.append('file', selectedFile, selectedFile.name);
    if (selectAction === 'User_creation') {
      formData.append('client_id', selectedClient?.value);
    } else {
      formData.append('flag_type', selectAction);
    }
    setProgress({ done: null, percentage: 0 });
    setActionText(selectAction);
    setFileName(selectedFile);
    setFileData(formData);
    setUploadDisabled(true);
    setLoaderText('File Validation is in progress...');
    try {
      fileValidate(SUBTYPE[selectAction], formData);
    } catch (err) {
      console.log(err);
      setUploadDisabled(false);
    }
  };

  const isButtonDisabled = () => (selectAction === 'User_creation' ? Object.keys(selectedClient).length === 0 : !selectAction) || uploadDisabled;

  const requiredFieldLabel = (label, className) => (
    <Box className={`${classes.label} ${className || ''}`}>
      <Typography>{label}</Typography>
      <span className={classes.red}>*</span>
    </Box>
  );

  const onViewStatus = useCallback(() => {
    batchUpdates(() => {
      setOpenStatusModal(true);
    });
  }, []);

  const onClientIdChange = (e) => {
    console.log(e);
    setSelectedClient(e);
  };

  const ModalComponent = modalSchema[modalState] ? modalSchema[modalState].component : null;
  return (
    <Paper elevation={0} className={classes.mainWrapper}>
      {
        (isLoading) && (
          <CustomUploadModal open contentWrapper={classes.contentWrapper} breakpoint="sm">
            <LoadingCircle labelText={loaderText} />
          </CustomUploadModal>
        )
      }
      {
        (showElement || isLoading) && (
          <AlertReleaseInfo
            progress={progress}
            showElement={showElement || isLoading}
            progressMessage={isLoading
              ? ALERTBAR_TEXT.validate[actionText](fileName?.name || '')
              : ALERTBAR_TEXT.progress[actionText](fileName?.name || '')}
            withErrorsMessage={ALERTBAR_TEXT.complete_with_errors[actionText](fileName?.name || '')}
            failedMessage={ALERTBAR_TEXT.error[actionText](fileName?.name || '')}
            successMessage={ALERTBAR_TEXT.success[actionText](fileName?.name || '')}
            onViewStatus={onViewStatus}
            setShowElement={setShowElement}
          />
        )
      }
      <Box className={classes.breadCrumbSection}>
        <BreadCrumbHOC
          list={breadCrumbList}
        />
      </Box>
      <Header
        heading="Bulk Action for User Management"
        subHeading="Bulk Upload for actions to be performed on users on the platform"
        className={classes.pageHeader}
      />

      <Paper className={classes.wrapper} elevation={0}>
        {
          selectAction && (
            <WarningInfoAlert severity={'warning'}>
              {`Note: ${WARNING_text[selectAction]}`}
            </WarningInfoAlert>
          )
        }

        <Container maxWidth={false} className={classes.middleWrapper}>
          <Box className={classes.selectWrapper}>
            <CustomSelect
              name="select_action"
              options={userActionOptions}
              className={`${classes.fieldPadding} ${classes.experience_type_label}`}
              defaultValue={
                selectAction
                  ? userActionOptions.find((item) => item.value === selectAction)
                  : null
              }
              placeholder="Select an Action"
              type="select"
              label={requiredFieldLabel('Choose a bulk action to be performed on the users', classes.experience_type_label)}
              onChange={(v) => setSelectAction(v.value)}
              disabled={uploadDisabled}
            />
          </Box>
          <Divider variant="fullWidth" className={classes.divider} light />
          {
            selectAction === 'User_creation' && (
            <Box className={classes.selectWrapper}>
              <CustomSelect
                selectRef={clientIdRef}
                name="client_id"
                options={clientIdList}
                className={`${classes.fieldPadding} ${classes.experience_type_label}`}
                defaultValue={Object.keys(selectedClient).length ? selectedClient : null}
                placeholder="Select Client ID and name"
                type="select"
                label={requiredFieldLabel('Client ID and name', classes.experience_type_label)}
                labelClass={classes.experience_type_label}
                onChange={(e) => onClientIdChange(e)}
                isOptionsNotLoading
                selectIconLoading={loading}
                noOptionsMessage="Client does not exist. Please contact local admin."
              />
            </Box>
            )
          }

          <Box className={classes.border}>
            <FileInput
              idx={0}
              onFileInput={onUpload}
              isDisabled={isButtonDisabled()}
            >
              <MainButton
                title="Upload XLS file"
                subtitle={UPLOAD_text[selectAction]}
                isDisabled={isButtonDisabled()}
              />
            </FileInput>
          </Box>
          <Divider variant="fullWidth" className={classes.divider} light />
          <Box className={classes.downloadTempBtn}>
            <Typography variant="body1" className={classes.downloadWaveDataLbl}>Need help configuring the file? Download the template below.</Typography>
            <Button
              variant="outlined"
              style={{ marginTop: '0.75rem', width: 'fit-content' }}
              color="primary"
              onClick={onDownloadSample}
            >
              Download Sample Template
            </Button>
          </Box>
        </Container>
      </Paper>
      {modalState && (
        <CustomUploadModal open onClose={onCloseModal} {...modalSchema[modalState].wrapperProps}>
          <ModalComponent {...modalSchema[modalState].props} />
        </CustomUploadModal>
      )}
      {openStatusModal ? (
        <ProgressStatusModal
          open={openStatusModal}
          onClose={() => { setOpenStatusModal(false); }}
          progress={progress}
          action={actionText}
        />
      ) : null}
      <ErrorModal open={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)} />
    </Paper>
  );
};

BulkUsersUpload.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string,
      programSubType: PropTypes.string,
    }),
  }).isRequired,
  isCpRole: PropTypes.bool.isRequired,
  fileValidateError: PropTypes.array.isRequired,
  fileValidateWarning: PropTypes.array.isRequired,
  fileValidate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  fileUpload: PropTypes.func.isRequired,
  transaction_id: PropTypes.number.isRequired,
  clearMessage: PropTypes.func.isRequired,
  clearTransactionId: PropTypes.func.isRequired,
  userCount: PropTypes.number.isRequired,
  usersData: PropTypes.object.isRequired,
};

export default BulkUsersUpload;
